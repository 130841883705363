import { memo } from 'react';
import Link from 'next/link';

import * as S from './SocialNetworks.sc';

const SocialNetworks = () => {
  return (
    <S.Wrapper>
      <Link href="https://www.facebook.com/thecollectorio" passHref legacyBehavior>
        <S.Facebook>
          <span className="visually-hidden">Facebook</span>
        </S.Facebook>
      </Link>
      <Link href="https://www.instagram.com/thecollectorio" passHref legacyBehavior>
        <S.Instagram>
          <span className="visually-hidden">Instagram</span>
        </S.Instagram>
      </Link>
      <Link href="https://twitter.com/TheCollectorIO" passHref legacyBehavior>
        <S.Twitter>
          <span className="visually-hidden">Twitter</span>
        </S.Twitter>
      </Link>
    </S.Wrapper>
  );
};

export default memo(SocialNetworks);
