import { memo } from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import SocialNetworks from '../SocialNetworks/SocialNetworks';

const Wrapper = styled.header`
  background-color: var(--theme-color-grey1);
  padding: 0.5rem 0 0.25rem;
  position: relative;

  @media ${(props) => props.theme.breakpoints.lg} {
    padding: 1rem 0 0;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: ${(props) => props.theme.maxHeaderWidth};
  padding: 0.5rem 1rem;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.lg} {
    padding: 1rem;
  }
`;

const TextContainer = styled.div`
  border-top: solid 1px var(--theme-color-grey2);
  margin-bottom: 2rem;
  padding-top: 1.5rem;

  @media ${(props) => props.theme.breakpoints.lg} {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
  }
`;

const Copyright = styled.p`
  font-weight: 600;
  letter-spacing: -0.03em;
  text-align: left;
`;

const Links = styled.p`
  font-size: 1rem;
  text-align: right;
`;

const Notice = styled.p`
  font-size: 1rem;
  margin-bottom: 1.5rem;
  text-align: center;
`;

type FooterProps = {
  title: string;
};

const Footer = ({ title }: FooterProps) => {
  return (
    <Wrapper>
      <Container>
        <SocialNetworks />
        <Notice>
          This site contains affiliate links. When you purchase qualifying
          products from those links we may earn a commission that helps support
          the site.
        </Notice>
        <TextContainer>
          <Copyright>
            &copy; 2015-{new Date().getFullYear()} {title}
          </Copyright>
          <Links>
            <Link href="/privacy-policy">
              Privacy Policy
            </Link>{' '}
            -{' '}
            <Link href="/cookies">
              Cookies
            </Link>{' '}
            -{' '}
            <Link href="/about">
              About
            </Link>{' '}
            -{' '}
            <Link href="/about#contact">
              Contact
            </Link>
          </Links>
        </TextContainer>
      </Container>
    </Wrapper>
  );
};

export default memo(Footer);
